import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Facebook from "./Facebook";
import Twitter from "./Twitter";
import { useIntl } from "gatsby-plugin-intl";
// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = props => {
  const {
      title,
      description,
      image,
      keywords,
      canonical_url
  } = props;

  const seo = {
    title: title || "Mosano",
    description: description || "Mosano",
    image: image,
    url: canonical_url,
    keywords,
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: 'https://mosano.eu',
    headline: 'Mosano',
    inLanguage: 'EN-US',
    mainEntityOfPage: 'https://mosano.eu',
    description: seo.description,
    name: seo.title,
    author: {
      '@type': 'Organization',
      name: 'Mosano',
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: 'Mosano',
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      '@type': 'Organization',
      name: 'Mosano',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Mosano',
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: Date.now(),
    image: {
      '@type': 'ImageObject',
      url: seo.image,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': 'https://mosano.eu',
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }
  const intl = useIntl()
  return (
    <div>
      <Helmet title={seo.title}>
        <html lang={intl.locale === "en" ? "en-us" : "pt-pt"} />
        <title>{seo.title || 'Mosano'}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="author" content="Mosano" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type="website"
        url={seo.canonical_url}
        locale={intl.locale === "en" ? "en-us" : "pt-pt"}
        name="Mosano"
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username="mosanotech" />
    </div>
  )
}

export default SEO

SEO.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  canonical_url: PropTypes.object,
  keywords: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
}

SEO.defaultProps = {
  name: null,
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  node: null,
}
